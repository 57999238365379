import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, useGridApiRef, GridNoRowsOverlay } from '@mui/x-data-grid';
import { Button, Popover, Chip, Box, Typography, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import DialogRoom from './Room';
import dayjs from 'dayjs';

const Rooms = ({ rooms, updateRooms }) => {
  const [selectedRoom, setSeletedRoom] = React.useState(null);
  const apiRef = useGridApiRef();
  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" onClick={() => setSeletedRoom({
          id: uuidv4(),
          type: '',
          checkIn: dayjs(),
          checkOut: dayjs().add(1, 'day'),
          quantity: 1,
        })}>
          Add Item
        </Button>
      </GridToolbarContainer >
    );
  }

  const columns = [
    {
      field: 'type',
      headerName: 'Room Type',
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: false,
    },
    {
      field: 'checkIn',
      headerName: 'Check In Date',
      width: 160,
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : null,
    },
    {
      field: 'checkOut',
      headerName: 'Check Out Date',
      width: 160,
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : null,
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      type: 'number',
      width: 80,
      editable: false,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      headerName: 'Actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ fontSize: 15 }} />}
            onClick={() => {
              deleteRoom(id);
            }}
            label="Delete"
            color="inherit"
          />
        ];
      }
    },
  ];

  const updateRoom = (room) => {
    const index = rooms.findIndex((i) => i.id === room.id);
    if (index === -1) {
      updateRooms([...rooms, room]);
    } else {
      const newRooms = rooms.filter((i) => i.id !== selectedRoom.id);
      newRooms.splice(index, 0, room);
      updateRooms(newRooms);
    }
  }

  const deleteRoom = (id) => {
    const newRooms = rooms.filter((i) => i.id !== id);
    updateRooms(newRooms);
  }

  return (
    <>
      {selectedRoom && <DialogRoom room={selectedRoom} updateRoom={updateRoom} close={() => setSeletedRoom(null)} />}
      <Box sx={{
        '& .MuiDataGrid-cell': {
          cursor: 'pointer'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        width: '100%'
      }}>
        <DataGrid
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" paddingTop={10} alignItems="center" justifyContent="center">
                No rows
              </Stack>
            ),
          }}
          disableColumnMenu
          autoHeight={true}
          apiRef={apiRef}
          editMode="row"
          rows={rooms.filter((i) => !i.deleted)}
          columns={columns}
          onRowClick={(e) => setSeletedRoom(rooms.find((i) => i.id === e.id))}
          hideFooter={true}
        />
      </Box>
    </>
  );
}

export default Rooms;
