import * as React from 'react';
import {
  Button, IconButton, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Select, MenuItem, InputLabel, FormControl,
  Grid, Box, Card, CardContent, Typography, AppBar, Toolbar, Checkbox, FormControlLabel
} from '@mui/material';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import { decimalsCount } from '../../utils/etc';
import dayjs from 'dayjs';
import {
  validateContactNumber, dateTimeFormat, dateFormat, validateEmail
} from '../../utils/etc';
import { print } from '../../utils/print';
import Items from './Items';
import AppContext from '../../context';
import { getLoginSession } from '../../services/auth-service';
import Payments from './Payments';
import Summary from './Summary';
import NumberInput from '../NumberInput';
import { createInvoice, getInvoice, updateInvoice } from '../../services/invoice-service';
import { Sources } from '../../configs/system';
import ReservationVoucherPayments from './ReservationVoucherPayments';
import { FileText as FileTextIcon } from 'react-feather';
import Logs from './Logs';

const sum = (accumulator, a) => accumulator + parseFloat(a);
const Invoice = ({ id, close }) => {
  const newState = {
    id: dayjs().format('YYMM-DD' + Math.floor((Math.random() * 10000) + 1)),
    name: '',
    contact: '',
    email: '',
    carNumbers: '',
    xqCars: '',
    dueDate: dayjs().startOf('day').format(dateFormat),
    arrivalTime: dayjs().startOf('day').format(dateTimeFormat),
    arrivalTimeConfirmed: true,
    departureTime: dayjs().startOf('day').add(1, 'day').format(dateTimeFormat),
    departureTimeConfirmed: true,
    pickUp: '',
    dropOff: '',
    remark: '',
    notes: '',
    operatorNotes: '',
    source: '',
    nationality: '',
    adults: 1,
    children: 0,
    infants: 0,
    assignee: getLoginSession().sub,
    personInCharge: getLoginSession().sub,
    currency: 'RM',
    currencyRate: 1.00,
    status: 'new',
    isNew: true,
  };
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState(newState);
  const [items, setItems] = React.useState([]);
  const [reservationVoucherPayments, setReservationVoucherPayments] = React.useState([]);
  const [payments, setPayments] = React.useState([]);
  const [openLogs, setOpenLogs] = React.useState(false);

  const loadInvoice = async () => {
    try {
      appContext.setLoading(true);
      if (id && id !== 'New') {
        const data = await getInvoice(id);
        setState(data);
        setItems(data.InvoiceItems);
        setPayments(data.InvoicePayments);
        setReservationVoucherPayments(data.ReservationVoucherPayments);
      }
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Error in procesing this request: ${e}`);
    } finally {
      appContext.setLoading(false);
    }
  };

  React.useState(() => {
    loadInvoice();
  }, []);

  const total = parseFloat(items.filter((i) => !i.deleted).map((i) => i.price * i.quantity).reduce(sum, 0)).toFixed(2);
  const payment = parseFloat(payments
    .filter((p) => p.status === 'approved')
    .map((p) => (p.type === 'revert' ? (-1 * p.amount) : p.amount))
    .reduce(sum, 0))
    .toFixed(2);
  const balance = parseFloat(total - payment).toFixed(2);

  const validateInvoice = () => {
    const maybeError = {
      ...error,
      name: !state.name || state.name === '',
      contact: !state.contact || state.contact.length < 10 || !validateContactNumber(state.contact),
      departureTime: dayjs(state.departureTime) < dayjs(state.arrivalTime),
      pickUp: !state.pickUp,
      dropOff: !state.dropOff,
      email: state.email && !validateEmail(state.email),
      source: !state.source,
      nationality: !state.nationality,
      currency: !state.currency,
      currencyRate: !state.currencyRate,
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    const newState = { ...state, [e.target.name]: e.target.value };
    if (e.target.name === 'arrivalTime' && state.isNew && !state.id.startsWith(dayjs(e.target.value).format('YYMM-DD'))) {
      newState.id = dayjs(e.target.value).format('YYMM-DD' + Math.floor((Math.random() * 10000) + 1));
    }
    if (e.target.name === 'arrivalTimeConfirmed' || e.target.name === 'departureTimeConfirmed') {
      newState[e.target.name] = e.target.checked;
    }
    if (e.target.name === 'currencyRate') {
      let value = e.target.value.replace('e', '');
      if (decimalsCount(value) > 2) {
        value = parseFloat(value).toFixed(2);
      }
      newState[e.target.name] = value
    }
    setState(newState);
  };

  const handleGuestChanged = (guest, type) => {
    switch (guest) {
      case 'adults':
        if (type === '-' && state.adults === 1) {
          return;
        }
        setState({ ...state, adults: type === '+' ? state.adults + 1 : state.adults - 1 })
        break;
      case 'children':
        if (type === '-' && state.children === 0) {
          return;
        }
        setState({ ...state, children: type === '+' ? state.children + 1 : state.children - 1 })
        break;
      case 'infants':
        if (type === '-' && state.infants === 0) {
          return;
        }
        setState({ ...state, infants: type === '+' ? state.infants + 1 : state.infants - 1 })
        break;
      default:
    }
  };

  const save = async () => {
    validateInvoice();
    if ((!state.name || state.name === '')
      || (!state.contact || state.contact.length < 10 || !validateContactNumber(state.contact))
      || dayjs(state.departureTime) < dayjs(state.arrivalTime)
      || !state.pickUp || !state.dropOff || !state.source || !state.nationality
      || (state.email && !validateEmail(state.email))
      || (!state.currencyRate)) {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }
    try {
      let cont = false;
      if (!state.isNew && id.indexOf(dayjs(state.arrivalTime).format('YYMM-DD')) === -1) {
        cont = window.confirm('Changing arrival date will re-create a new invoice. Are you sure you want to proceed?');
        if (!cont) {
          return;
        }
      }

      appContext.setLoading(true);
      const rv = reservationVoucherPayments.filter((v) => items.filter((i) => !i.deleted).map(i => i.id).includes(v.invoiceItemId));
      if (state.isNew || cont) {

        const created = await createInvoice(state, items, payments, rv);
        appContext.showMessage('success', `Invoice ${created.id} created successfully.`);
      } else {
        await updateInvoice(id, state, items, payments, rv);
        appContext.showMessage('success', `Invoice ${id} updated.`);
      }
      close(true);
    } catch (e) {
      console.error(e);
      if (e.response.data.startsWith('Validation error')) {
        appContext.showMessage('error', `Invoice id already exists in the system.`);
      } else {
        appContext.showMessage('error', `Invoice save failed: ${e.response.data}.`);
      }
    } finally {
      appContext.setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={close}  >
      <DialogTitle>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h4" component="div">
              {`Invoice - ${id}`}  <IconButton sx={{ color: 'white' }} onClick={() => setOpenLogs(true)} size="small" aria-label="delete"> <FileTextIcon /></IconButton>
            </Typography>
            <Button
              color="inherit"
              onClick={() => {
                window.navigator.clipboard.writeText(`${window.location.origin}/invoices/${id}`);
                appContext.showMessage('success', 'Customer link copied.');
              }}
            >
              Copy Customer Link
            </Button> |
            <Button
              color="inherit"
              onClick={() => {
                const printWindow = window.open("", "print", "width=" + window.screen.availWidth * 0.8 + ",height=" + window.screen.availHeight * 0.8 + ",scrollbars=1,resizable=1");
                printWindow.document.open();
                printWindow.document.write(print(state, items, reservationVoucherPayments, payments, appContext.options.users));
                printWindow.document.close();
              }}
            >
              Print
            </Button> |
            <Button autoFocus onClick={save} color="inherit">
              save
            </Button> |
            <Button autoFocus onClick={() => close(true)} color="inherit">
              Close
            </Button>
          </Toolbar>
        </AppBar>

      </DialogTitle>
      <DialogContent style={{ height: '80vh', margin: 15 }}>
        {openLogs && <Logs id={id} close={() => setOpenLogs(false)} />}
        <Typography variant="h6">
          Workflow
        </Typography>
        <Card
          variant="outlined"
          sx={{
            borderColor: '#B5B5B2', borderWidth: 1
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    disabled={id === 'New'}
                    value={state.status}
                    labelId="status-select-label"
                    id="status"
                    name="status"
                    label="Status"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="booking">Booking</MenuItem>
                    <MenuItem value="costing">Costing</MenuItem>
                    <MenuItem value="invoice">Invoice</MenuItem>
                    <MenuItem value="unpaid">Unpaid</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                    <MenuItem value="operating">Operating</MenuItem>
                    <MenuItem value="completed" disabled={balance > 0}>Complete</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="assignee-select-label">Assignee</InputLabel>
                  <Select
                    value={state.assignee}
                    labelId="assignee-select-label"
                    id="assignee"
                    name="assignee"
                    label="Assignee"
                    size="small"
                    onChange={handleInputChange}
                  >
                    {appContext.options.users.length > 0 && appContext.options.users.filter((u) => u.active).map((a) => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <br />
        <Typography variant="h6">
          Details
        </Typography>
        <Card variant="outlined" sx={{ borderColor: '#B5B5B2', borderWidth: 1 }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth sx={{ paddingTop: 1 }}>
                  <InputLabel id="personInCharge-select-label">Person In Charge</InputLabel>
                  <Select
                    value={state.personInCharge}
                    labelId="assignee-select-label"
                    id="personInCharge"
                    name="personInCharge"
                    label="Person In Charge"
                    size="small"
                    onChange={handleInputChange}
                  >
                    {appContext.options.users.length > 0 && appContext.options.users.filter((u) => u.active).map((a) => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  value={state.xqCars}
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="xqCars"
                  name="xqCars"
                  label="XQ Cars"
                  size="small"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  value={state.carNumbers}
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="carNumbers"
                  name="carNumbers"
                  label="Car"
                  size="small"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  error={error.name}
                  helperText={error.name ? 'Invalid input.' : ''}
                  value={state.name}
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  size="small"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  value={state.contact}
                  error={error.contact}
                  helperText={error.contact ? 'Invalid input.' : ''}
                  fullWidth
                  margin="dense"
                  id="contact"
                  name="contact"
                  size="small"
                  label="Contact Number"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  value={state.email}
                  error={error.email}
                  helperText={error.email ? 'Invalid input.' : ''}
                  fullWidth
                  margin="dense"
                  id="email"
                  name="email"
                  size="small"
                  label="Email"
                  type="text"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth sx={{ paddingTop: 1 }}>
                  <InputLabel id="nationality-select-label">Nationality</InputLabel>
                  <Select
                    value={state.nationality}
                    labelId="nationality-select-label"
                    id="nationality"
                    name="nationality"
                    label="Nationality"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Malaysian">Malaysian</MenuItem>
                    <MenuItem value="Non-Malaysian">Non-Malaysian</MenuItem>
                  </Select>
                  {error.nationality && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">ETA</InputLabel>
                  <MobileDateTimePicker
                    size="small"
                    renderInput={(params) => <TextField size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'arrivalTime', value: newValue } });
                    }}
                    value={state.arrivalTime}
                    inputFormat="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
                <FormControlLabel sx={{ paddingLeft: 2 }} control={<Checkbox onChange={handleInputChange} name="arrivalTimeConfirmed" checked={state.arrivalTimeConfirmed} />} label="Confirmed" />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">ETD</InputLabel>
                  <MobileDateTimePicker
                    size="small"
                    renderInput={(params) => <TextField size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'departureTime', value: newValue } });
                    }}
                    value={state.departureTime}
                    inputFormat="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
                <FormControlLabel sx={{ paddingLeft: 2 }} control={<Checkbox onChange={handleInputChange} name="departureTimeConfirmed" checked={state.departureTimeConfirmed} />} label="Confirmed" />
                {error.departureTime && (
                  <span style={{ color: '#d32f2f', fontSize: 12, paddingLeft: 15 }}>
                    Departure time cannot be earlier than arrival time.
                  </span>
                )}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="pickup-select-label">Location</InputLabel>
                  <Select
                    value={state.pickUp}
                    labelId="pickup-select-label"
                    id="pickUp"
                    name="pickUp"
                    label="Location"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="airport">Airport</MenuItem>
                    <MenuItem value="hotel">Hotel</MenuItem>
                    <MenuItem value="jetty">Jetty</MenuItem>
                    <MenuItem value="roro">Roro</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {error.pickUp && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="dropOff-select-label">Location</InputLabel>
                  <Select
                    value={state.dropOff}
                    labelId="dropOff-select-label"
                    id="dropOff"
                    name="dropOff"
                    label="Location"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="airport">Airport</MenuItem>
                    <MenuItem value="hotel">Hotel</MenuItem>
                    <MenuItem value="jetty">Jetty</MenuItem>
                    <MenuItem value="roro">Roro</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {error.dropOff && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Due Date</InputLabel>
                  <MobileDatePicker
                    size="small"
                    renderInput={(params) => <TextField label="Due Date" fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'dueDate', value: newValue } });
                    }}
                    value={state.dueDate}
                    inputFormat="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="source-select-label">Source</InputLabel>
                  <Select
                    value={state.source}
                    labelId="source-select-label"
                    id="source"
                    name="source"
                    label="Source"
                    size="small"
                    onChange={handleInputChange}
                  >
                    {Sources.map((t) => <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)}
                  </Select>
                  {error.source && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl sx={{ marginTop: 2 }} fullWidth>
                  <InputLabel id="currency-select-label">Currency</InputLabel>
                  <Select
                    value={state.currency}
                    labelId="currency-select-label"
                    id="currency"
                    name="currency"
                    label="currency"
                    size="small"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="RM">MYR</MenuItem>
                    <MenuItem value="CNY">CNY</MenuItem>
                  </Select>
                  {error.currency && (
                    <span style={{
                      color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                    }}
                    >
                      Invalid input.
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12} sx={{ marginTop: 1 }}>
                <TextField
                  value={state.currencyRate}
                  error={error.currencyRate}
                  helperText={error.currencyRate ? 'Invalid input.' : ''}
                  fullWidth
                  margin="dense"
                  id="currencyRate"
                  name="currencyRate"
                  size="small"
                  type="number"
                  label="Currency Rate"
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel sx={{ marginTop: 1, paddingLeft: 1, fontSize: 12 }}>Guests</InputLabel>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: 2, paddingBottom: 2, border: 'solid 1px #B5B5B2', borderRadius: 1 }}>
                  <NumberInput title="Adults" value={state.adults} increase={() => handleGuestChanged('adults', '+')} decrease={() => handleGuestChanged('adults', '-')} />
                  <NumberInput title="Children" value={state.children} increase={() => handleGuestChanged('children', '+')} decrease={() => handleGuestChanged('children', '-')} />
                  <NumberInput title="Infants" value={state.infants} increase={() => handleGuestChanged('infants', '+')} decrease={() => handleGuestChanged('infants', '-')} />
                </Box>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.remark}
                  fullWidth
                  id="remark"
                  name="remark"
                  label="Remark"
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 5000 }}
                  helperText="Max 5000 characters"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.notes}
                  fullWidth
                  id="notes"
                  name="notes"
                  label="Internal Notes"
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 5000 }}
                  helperText="Max 5000 characters"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.operatorNotes}
                  fullWidth
                  id="operatorNotes"
                  name="operatorNotes"
                  label="Operator Notes"
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 5000 }}
                  helperText="Max 5000 characters"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel sx={{ marginTop: 1, paddingLeft: 1, fontSize: 12 }}>Items</InputLabel>
                <Items updateItems={setItems} items={items} />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel sx={{ marginTop: 1, paddingLeft: 1, fontSize: 12 }}>Reservation Vouchers</InputLabel>
                <ReservationVoucherPayments items={items} updatePayments={setReservationVoucherPayments} payments={reservationVoucherPayments} />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel sx={{ marginTop: 1, paddingLeft: 1, fontSize: 12 }}>Payments</InputLabel>
                <Payments invoiceId={id} updatePayments={setPayments} payments={payments} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Box sx={{ paddingRight: 6 }}>
          <Summary total={total} balance={balance} payment={payment} />
        </Box>
      </DialogActions>
    </Dialog >
  );
};

export default Invoice;
