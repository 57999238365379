import { httpGet, httpPost, httpPut } from '../utils/api';
import configs from '../configs';

export const getReservationVoucherPayments = async (statuses, searchText, page, limit) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/reservation-voucher-payments?statuses=${statuses}&search=${searchText}&page=${page}&limit=${limit}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const getReservationVoucher = async (id) => {
  const result = await httpGet(`${configs.apiBaseUrl}/api/internal/reservation-voucher/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` });
  return result;
};

export const createInvoice = async (invoice, items, payments, reservationVouchers) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/internal/invoices`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    { invoice, items, payments, reservationVouchers }
  );
  return result;
};

export const addReservationVoucher = async (invoiceId, itemId, date, name, itemName, rooms, stamping, status, remark) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/internal/reservation-vouchers`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    { invoiceId, itemId, date, name, itemName, rooms, stamping, status, remark }
  );
  return result;
};

export const updateReservationVoucher = async (id, date, name, itemName, rooms, stamping, status, remark) => {
  const result = await httpPost(`${configs.apiBaseUrl}/api/internal/reservation-voucher/${id}`,
    { Authorization: `JWT ${window.localStorage.getItem(configs.jwtStorageKey)}` },
    { date, name, itemName, rooms, stamping, status, remark }
  );
  return result;
};