import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid';
import { Button, Popover, Chip, Box, Typography, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ReservationVoucherPayment from './ReservationVoucherPayment';
import { getLoginSession } from '../../services/auth-service';
import dayjs from 'dayjs';

const RenderLongText = (param) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isLongText = param.value.length > 20;

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {isLongText ? param.value.substring(0, 20) + '...' : param.value}
      </Typography>
      {isLongText &&
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{param.value}</Typography>
        </Popover>
      }
    </div>
  );
}

const ReservationVoucherPayments = ({ items, payments, updatePayments }) => {
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const apiRef = useGridApiRef();
  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" onClick={() => {
          if (items.filter((i) => !i.deleted).length === 0) {
            alert('Item list is empty. Please add an item first.')
            return;
          }
          setSelectedPayment({
            id: uuidv4(),
            invoiceItemId: '',
            paymentType: 'mbb',
            paymentDate: dayjs().format('YYYY-MM-DD'),
            amount: '',
            remark: '',
            isNew: true,
          });
        }}>
          Add Vouchers
        </Button>
      </GridToolbarContainer >
    );
  }

  const columns = [
    {
      field: 'invoiceItemId',
      headerName: 'Item',
      width: 200,
      sortable: false,
      editable: false,
      valueGetter: ({ value }) => {
        const item = items.find((i) => i.id === value);
        return `${item.itemId} | ${item.billNo}`;
      },
    },
    {
      field: 'paymentType',
      headerName: 'Payment Type',
      width: 130,
      sortable: false,
      editable: false,
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      width: 130,
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : null,
    },
    {
      field: 'amount',
      hide: getLoginSession().role !== 1,
      headerName: 'Amount',
      type: 'number',
      width: 120,
      editable: false,
      sortable: false,
      valueGetter: ({ value }) => parseFloat(value).toFixed(2),
    },
    {
      field: 'remark',
      headerName: 'Remark',
      minWidth: 200,
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: RenderLongText
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      headerName: 'Actions',
      getActions: ({ id }) => {
        const payment = payments.find((v) => v.id === id)
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ fontSize: 15 }} />}
            disabled={!payment.isNew}
            onClick={() => {
              deletePayment(id);
            }}
            label="Delete"
            color="inherit"
          />
        ];
      }
    },
  ];

  const updatePayment = (voucher) => {
    const index = payments.findIndex((i) => i.id === voucher.id);
    if (index === -1) {
      updatePayments([...payments, voucher]);
    } else {
      const newPayments = payments.filter((i) => i.id !== selectedPayment.id);
      newPayments.splice(index, 0, voucher);
      updatePayments(newPayments);
    }
  }

  const deletePayment = (id) => {
    const newPayments = payments.filter((i) => i.id !== id);
    updatePayments(() => newPayments);
  }

  return (
    <>
      {selectedPayment && <ReservationVoucherPayment items={items} payment={selectedPayment} updatePayment={updatePayment} close={() => setSelectedPayment(null)} />}
      <Box sx={{
        '& .MuiDataGrid-cell': {
          cursor: 'pointer'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        width: '100%'
      }}>
        <DataGrid
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" paddingTop={10} alignItems="center" justifyContent="center">
                No rows
              </Stack>
            ),
          }}
          disableColumnMenu
          autoHeight={true}
          apiRef={apiRef}
          editMode="row"
          rows={payments.filter((v) => items.filter((i) => !i.deleted).map(i => i.id).includes(v.invoiceItemId))}
          columns={columns}
          onRowClick={(e) => {
            const v = payments.find((i) => i.id === e.id);
            if (v.isNew) {
              setSelectedPayment(payments.find((v) => v.id === e.id))
            }
          }
          }
          hideFooter={true}
        />
      </Box>
    </>
  );
}

export default ReservationVoucherPayments;
