import * as React from 'react';
import {
  Button, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Select, MenuItem, InputLabel, FormControl,
  Box, Card, CardContent, Typography, FormGroup
} from '@mui/material';
import AppContext from '../../context';
import { decimalsCount } from '../../utils/etc';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const ReservationVoucherPayment = ({ items, payment, close, updatePayment }) => {
  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [state, setState] = React.useState(payment);

  const validateVoucherPayment = () => {
    const maybeError = {
      ...error,
      invoiceItemId: !state.invoiceItemId,
      paymentType: !state.paymentType,
      paymentDate: !state.paymentDate,
      amount: state.amount === '',
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    switch (e.target.name) {
      case 'amount':
        value = value.replace('e', '');
        if (decimalsCount(value) > 2) {
          value = parseFloat(value).toFixed(2);
        }
        break;
      default: break;
    }
    setState({ ...state, [e.target.name]: value });
  };

  const save = async () => {
    validateVoucherPayment();
    if (!state.invoiceItemId || state.amount === '') {
      appContext.showMessage('warning', 'Please fill in all the required fields.');
      return;
    }
    updatePayment(state);
    close(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={close}>
      <DialogTitle>
        <Typography variant="h4">
          Reservation Voucher Payment
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ borderColor: '#B5B5B2', borderWidth: 1 }}>
          <CardContent>
            <FormGroup>
              <FormControl fullWidth>
                <InputLabel id="itemId-select-label">Item</InputLabel>
                <Select
                  value={state.invoiceItemId}
                  labelId="itemId-select-label"
                  id="invoiceItemId"
                  name="invoiceItemId"
                  label="Item"
                  size="small"
                  onChange={handleInputChange}
                >
                  {items.filter((i) => !i.deleted).map((i) => <MenuItem key={i.id} value={i.id}>Item: {i.itemId} | Bill No: {i.billNo}</MenuItem>)}
                </Select>
                {error.invoiceItemId && (
                  <span style={{
                    color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                  }}
                  >
                    Invalid input.
                  </span>
                )}
              </FormControl>
              <br />
              <FormControl fullWidth>
                <InputLabel id="paymentType-select-label">Payment Type</InputLabel>
                <Select
                  value={state.paymentType}
                  labelId="paymentType-select-label"
                  id="paymentType"
                  name="paymentType"
                  label="payment type"
                  size="small"
                  onChange={handleInputChange}
                >
                  <MenuItem value="mbb">MBB</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="creditcard">Credit Card</MenuItem>
                  <MenuItem value="contra">Contra</MenuItem>
                  <MenuItem value="revert">Revert</MenuItem>
                </Select>
                {error.paymentType && (
                  <span style={{
                    color: '#d32f2f', marginTop: 5, fontSize: 13, paddingLeft: 15
                  }}
                  >
                    Invalid input.
                  </span>
                )}
              </FormControl>
              <Box sx={{ paddingBottom: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel sx={{ paddingLeft: 1, marginTop: 0, fontSize: 12 }} size="small">Transaction Date</InputLabel>
                  <br />
                  <MobileDatePicker
                    size="small"
                    renderInput={(params) => <TextField label="Transaction Date" fullWidth size="small" {...params} />}
                    onChange={(newValue) => {
                      handleInputChange({ target: { name: 'paymentDate', value: newValue} });
                    }}
                    value={state.paymentDate}
                    inputFormat="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              </Box>
              <FormControl fullWidth>
                <TextField
                  error={error.amount}
                  helperText={error.amount ? 'Invalid input.' : ''}
                  value={state.amount}
                  fullWidth
                  margin="dense"
                  id="amount"
                  name="amount"
                  label="Amount (RM)"
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ marginTop: 2 }}
                  value={state.remark}
                  fullWidth
                  id="remark"
                  name="remark"
                  label="Remark"
                  multiline
                  rows={3}
                  inputProps={{ maxLength: 360 }}
                  onChange={handleInputChange}
                />
              </FormControl>
            </FormGroup>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <>
          <Button variant="contained" onClick={save}>Save</Button>
          <Button onClick={() => close(false)}>Close</Button>
        </>
      </DialogActions>
    </Dialog >
  );
};

export default ReservationVoucherPayment;
