import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import {
  Card, CardContent, Chip, Box, Container, Typography,
} from '@mui/material';
import DialogUser from '../components/users/DialogUser';
import GridView from '../components/GridView';
import { getUsers } from '../services/user-service';

const renderStatus = (params) => (
  <Typography variant="p">
    {params.value === 1 ? <Chip size="small" label="Active" color="primary" variant="outlined" /> : <Chip size="small" label="Inactive" color="warning" variant="outlined" />}
  </Typography>
);

const renderRole = (param) => {
  let role;
  switch (param.value) {
    case 1: role = 'System Administrator';
      break;
    case 1.9: role = 'Internal + Reservation';
      break;
    case 2: role = 'Internal';
      break;
    case 3: role = 'External (Operation)';
      break;
    case 0: role = 'Account';
      break;
    default: role = 'Unknown';
      break;
  }
  return (
    <Typography variant="p">
      {role}
    </Typography>
  );
};

const columns = [
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 400,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 250,
    renderCell: renderRole
  },
  {
    field: 'active',
    headerName: 'Status',
    width: 100,
    renderCell: renderStatus,
    sortable: false
  },
];


const Items = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    page: 0,
    data: {
      rows: [],
      count: 0
    }
  });

  const search = async (searchText, page) => {
    try {
      appContext.setLoading(true);
      const result = await getUsers(searchText, page, configs.pageSize);
      setState({
        ...state, page, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  useEffect(() => {
    search('', 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <GridView search={search} columns={columns} data={state.data} SelectedDialog={DialogUser} allowAdd={true} />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Items;
